import { HeroTypes, LocationTypes } from '@codeparva/gatsby-theme-layout-components';
import { getImageData } from '@codeparva/gatsby-theme-tfw-1/src/utils/getImageData';
import { getTransformedHours } from '@codeparva/gatsby-theme-tfw-1/src/components/homePageJumbotronDemo/transformer';
import { locationMapConfig } from '@codeparva/gatsby-theme-tfw-1/src/components/locationMap/config';
import { exampleLocation, heroSectionConfig } from '@codeparva/gatsby-theme-tfw-1/src/components/locationHero/config';

export const getCustomCTAType = (label: any) => {
  if (label.includes('call')) {
    return 'call';
  }
  if (label.includes('pay')) {
    return 'payRent';
  }
  if (label.includes('directions')) {
    return 'directions';
  }
  return 'payRent';
};
export const getCtaConfig = (cta_config: any) => {
  if (cta_config && cta_config.length > 0) {
    return cta_config.map(({ button_type, label, url, show_button, cta_button_theme }: any) => {
      return {
        buttonColor: button_type,
        show: show_button,
        label,
        link: url,
        theme: cta_button_theme?.section_theme || 'light',
        // customCTAtype: getCustomCTAType(label),
      };
    });
  }
};

const getSectionFooterConfig = (footerConfig: any) => {
  if (footerConfig && footerConfig.length > 0) {
    return {
      ctaButtons: footerConfig.map(
        ({ button_type, label, url, show_button, cta_button_theme }: any) => {
          return {
            buttonColor: button_type,
            show: show_button,
            label,
            link: url,
            theme: cta_button_theme?.section_theme || 'light',
            customCTAtype: getCustomCTAType(label),
          };
        },
      ),
    };
  }
  return undefined;
};

const getPhotos = (photos_config: any[]) => {
  if (photos_config && photos_config.length > 0) {
    return photos_config.map(({ hero_section_photo, caption }: any) => {
      if (hero_section_photo) {
        const { url } = hero_section_photo;
        const gatsbyImageData = getImageData(hero_section_photo);
        return {
          imageUrl: url,
          caption,
          gatsbyImageData,
        };
      }
    });
  }
  return photos_config;
};

const getLocationMapConfig = (
  facilityDetails: any,
  locationDetails: any,
): LocationTypes.ILocationMap => {
  const defaultLocationMapConfig = { ...locationMapConfig };
  if (facilityDetails) {
    defaultLocationMapConfig.googleMapAPIKey =
      facilityDetails?.google_my_business_details?.google_maps_api_key;
    defaultLocationMapConfig.useIframe = true;
  }
  if (locationDetails && locationDetails.length > 0) {
    // For now extracting only first location
    const curLocation = locationDetails[0];
    const transformedLocation = { ...exampleLocation[0] };
    const {
      attributes: { location_contact_details, location_name },      
    } = curLocation;
    if (location_contact_details) {
      const {
        location_facility_address,
        location_mailing_address,
        location_email_address,
        location_phone_number,
        location_google_maps_place_id,
        location_office_hours,
        location_access_hours,
        location_google_maps_url,
        maps_iframe_url
      } = location_contact_details;
      transformedLocation.locationUrl = location_google_maps_url;
      transformedLocation.addressText = location_facility_address;
      transformedLocation.mailingAddressText =
        location_mailing_address || location_facility_address;
      transformedLocation.emailAddress = location_email_address;
      transformedLocation.contactNumber = location_phone_number;
      transformedLocation.googleMapPlaceId = location_google_maps_place_id;
      transformedLocation.hours = {
        officeHrs: getTransformedHours(location_office_hours),
        gateHrs: getTransformedHours(location_access_hours),
      };
      transformedLocation.iFrameUrl = maps_iframe_url || '';
      
    }
    if (facilityDetails) {
      const { facility_name } = facilityDetails;
      transformedLocation.facilityName = location_name || facility_name;
    }
    defaultLocationMapConfig.locations = [transformedLocation];
  }
  return defaultLocationMapConfig;
};

export const transformLocationHeroConfig = (
  facilityDetails: any,
  locationDetails: any,
  strapiConfig: any,
): HeroTypes.ILocationHero => {
  const localConfigCopy = { ...heroSectionConfig };
  const {
    section_header_config,
    section_footer_config,
    section_variant,
    section_theme,
    photos_config,
  } = strapiConfig;
  localConfigCopy.sectionHeader = {
    title: section_header_config.title,
    cta: getCtaConfig(section_header_config.cta_config),
    subtitle: locationDetails[0].id ===14 ? 'Main Location' : 'Outdoor Parking / Containers'
  };
  localConfigCopy.sectionFooter = getSectionFooterConfig(section_footer_config);
  localConfigCopy.photos = getPhotos(photos_config);
  localConfigCopy.variant = section_variant.replace(/_/g, '-');
  localConfigCopy.theme = section_theme.section_theme;
  localConfigCopy.locationDetails = getLocationMapConfig(facilityDetails, locationDetails);
  localConfigCopy.media = strapiConfig.section_background_image;
  return localConfigCopy;
};
