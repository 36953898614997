import React from 'react';
import { Hero } from '@codeparva/gatsby-theme-layout-components';
import '../../override.scss';
import { GatsbyImage } from 'gatsby-plugin-image';
import { transformLocationHeroConfig } from './transformer';
import { useVariant } from '@codeparva/gatsby-theme-tfw-1/src/components/homePageJumbotronDemo/hooks/Heightvariant';

export interface IHeroSection extends React.HTMLAttributes<HTMLDivElement> {
  strapiHeroSectionConfig: any;
  facilityDetails: any;
  locationDetails: any;
}

export const HeroSection = ({
  facilityDetails,
  locationDetails,
  strapiHeroSectionConfig,
}: IHeroSection) => {
  const transformedHeroConfig = transformLocationHeroConfig(
    facilityDetails,
    locationDetails,
    strapiHeroSectionConfig,
  );
  if (transformedHeroConfig.variant === 'hero-section-3') {
    const bodyHeight = useVariant();
    const height = `calc(100vh - ${bodyHeight}px)`;
    return <Hero {...transformedHeroConfig} headHeight={height} />;
  } else {
    const {
      section_background_image: {
        file: {
          childImageSharp: { gatsbyImageData },
        },
      },
    } = strapiHeroSectionConfig;
    return (
      <div style={{ display: 'grid' }} className="locationHero">
        <GatsbyImage
          style={{
            gridArea: '1/1',
          }}
          alt=""
          className="bgCover"
          image={gatsbyImageData}
        />
        <div
          style={{
            gridArea: '1/1',
            position: 'relative',
            placeItems: 'center',
            display: 'grid',
          }}
          className="overlayContent"
        >
          <Hero {...transformedHeroConfig} />
        </div>
      </div>
    );
  }
};
